const resetButton = document.getElementById("resetButton") as HTMLButtonElement;
const consoleStartButton = document.getElementById("consoleStartButton") as HTMLButtonElement;
const terminal = document.getElementById("terminal");

//test constants for writing to esp
const wifiCredentialsButton = document.getElementById("wifiCredentialsButton") as HTMLButtonElement;
const scanButton = document.getElementById("scanButton") as HTMLButtonElement;
const showPasswordButton = document.getElementById("showButton") as HTMLButtonElement;
const hidePasswordButton = document.getElementById("hideButton") as HTMLButtonElement;
const cartridgeButton = document.getElementById("cartridgeButton") as HTMLButtonElement;
const hardwareButton = document.getElementById("hardwareButton") as HTMLButtonElement;

//test constants for bluetooth
const bleButton = document.getElementById("scanBleButton") as HTMLButtonElement;
const wifiCredentialsBleButton = document.getElementById("wifiCredentialsBleButton") as HTMLButtonElement;
let server = null;
// This is a frontend example of Esptool-JS using local bundle file
// To optimize use a CDN hosted version like
// https://unpkg.com/esptool-js@0.2.0/bundle.js
import { Transport } from "../lib";

declare let Terminal; // Terminal is imported in HTML script

const term = new Terminal({ cols: 80, rows: 26 });
term.open(terminal);

let device = null;
let transport: Transport;

wifiCredentialsButton.onclick = async () => {
    const ssid = document.getElementById('ssid') as HTMLInputElement
    const password = document.getElementById('password') as HTMLInputElement
    const uuid = document.getElementById('uuid') as HTMLInputElement

    let data = {
        subject: 2,
        ssid: ssid.value,
        password: password.value,
        uuid: uuid.value,
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

cartridgeButton.onclick = async () => {
    const cartridge = document.getElementById('cartridge') as HTMLInputElement
    let data = {
        subject: 3,
        volume: cartridge.value,
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

hardwareButton.onclick = async () => {
    const hardware = document.getElementById('hardware') as HTMLInputElement
    const status = document.getElementById('status') as HTMLInputElement
    const debug = document.getElementById('debug') as HTMLInputElement
    let data = {
        subject: 4,
        type: hardware.value,
        status: status.value,
        debug_message: debug.value
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}


scanButton.onclick = async () => {
    let data = {
        subject: 1
    };

    const message = new TextEncoder().encode(JSON.stringify(data))
    await transport.write(message)
}

resetButton.onclick = async () => {
    term.clear();
    // Additional cleanup code if needed
    await transport.disconnect();

    // Reset device and transport references
    device = null;
    transport = null;
};

bleButton.onclick = async () => {
    try {
        console.log('Requesting Bluetooth Device...');
        const device = await navigator.bluetooth.requestDevice({
            filters: [{ namePrefix: "PHUESE" },{ namePrefix: "GENIE" }],
            optionalServices: ['4fafc201-1f24-11ee-be56-0242ac120002', '4fafc201-1fb5-11ee-be56-0242ac120002']
        });

        const disconnected = document.getElementById('ble-disconnected') as HTMLElement;
        disconnected.style.display = 'none';
        const loaderConnection = document.getElementById('loaderConnection') as HTMLElement;
        loaderConnection.classList.remove("hidden");

        console.log('Connecting to GATT Server...');
        server = await device.gatt.connect();


        // const loader = document.getElementById('loaderWifi') as HTMLElement;
        console.log('Connected to ' + device.name);
        console.log(device.gatt);

        if (device.gatt.connected) {
            loaderConnection.classList.add("hidden");
            const connected = document.getElementById('ble-connected') as HTMLElement;
            connected.classList.remove("hidden");

            const wifiListBleContainer = document.getElementById('wifiListBleContainer') as HTMLElement;
            wifiListBleContainer.classList.remove("hidden");


            //------------------device uuid-------------------------
            console.log('Getting device Service...');
            const serviceUuid = await server.getPrimaryService('4fafc201-1fb5-11ee-be56-0242ac120002');
            const characteristicUuid = await serviceUuid.getCharacteristic('4fafc201-1b1c-11ee-be56-0242ac120002');
            const valueUuid = await characteristicUuid.readValue();

            let bytesUuid = [];

            for (let i = 0; i < valueUuid.byteLength; i++) {
                bytesUuid.push(valueUuid.getUint8(i));  // Haal elk byte op
            }

            const deviceUuid = bytesUuid.map(code => String.fromCharCode(code)).join('');
            console.log('device uuid : ' + deviceUuid);


            //-------------------wifi----------------------------
            console.log('Getting wifi Service...');
            const service = await server.getPrimaryService('4fafc201-1f24-11ee-be56-0242ac120002');//0x1801
            console.log(service);


            const characteristics = await service.getCharacteristics();
            console.log(characteristics);

            //get wifi list
            const characteristic = await service.getCharacteristic('4fafc201-0e97-11ee-be56-0242ac120002');
            const value = await characteristic.readValue();

            let bytes = [];

            for (let i = 0; i < value.byteLength; i++) {
                bytes.push(value.getUint8(i));  // Haal elk byte op
            }

            const wifiList = bytes.map(code => String.fromCharCode(code)).join('');
            console.log(wifiList);
            const wifiListArray = JSON.parse(wifiList)

            const wifiListView = document.getElementById('wifiListBle') as HTMLElement;
            const loader = document.getElementById('loaderWifi') as HTMLElement;

            wifiListView.innerHTML = null;
            wifiListArray.forEach(function (item) {
                let listItem = document.createElement("li");
                listItem.textContent = item.s;
                wifiListView.appendChild(listItem);
            });

            loader.classList.add("hidden");
            wifiListView.classList.remove("hidden");
        }
    } catch (error) {
        console.log('Error! ' + error);
    }

};

wifiCredentialsBleButton.onclick = () => {
    //-----write network credentials
    console.log('Writing network credentials...');
    let ssidBLE = document.getElementById('ssidBLE') as HTMLInputElement;
    let passwordBLE = document.getElementById('PasswordBLE') as HTMLInputElement;
    writeNetwork('4fafc201-36e1-11ee-be56-0242ac120002', 'SSID', ssidBLE.value, server)
    writeNetwork('4fafc201-192d-11ee-be56-0242ac120002', 'PASSWORD', passwordBLE.value, server)
    console.log(server);

    async function writeNetwork(char, type, val, server) {
        console.log(type);
        const service = await server.getPrimaryService('4fafc201-1f24-11ee-be56-0242ac120002');
        const characteristic = await service.getCharacteristic(char);
        const encoder = new TextEncoder();
        const buffer = encoder.encode(val);
        characteristic.writeValue(buffer);
    }
};


showPasswordButton.onclick = () => {
    const passwordInput = document.getElementById('password') as HTMLInputElement
    passwordInput.type = 'text';
    showPasswordButton.style.display = 'none';
    hidePasswordButton.style.display = 'block';
}

hidePasswordButton.onclick = () => {
    const passwordInput = document.getElementById('password') as HTMLInputElement
    passwordInput.type = 'password';
    showPasswordButton.style.display = 'block';
    hidePasswordButton.style.display = 'none';
}

let isConsoleClosed = false;
consoleStartButton.onclick = async () => {
    if (device === null) {
        device = await navigator.serial.requestPort({});
        transport = new Transport(device);
    }
    await transport.connect();

    term.write("Connected to ESP32. Ready to receive messages.");

    isConsoleClosed = false;

    while (!isConsoleClosed) {
        const val = await transport.rawRead();
        if (typeof val !== "undefined") {
            term.write(val);
        } else {
            break;
        }
    }

    console.log("quitting console");
};
